// 消息管理相关接口
import request from '@/utils/request'

var MESSAGE = '/message'

// 获取是否存在未读
export function postAppBulletinLogGetUnreadCount() {
    return request({
        baseURL: import.meta.env.VITE_APP_BASE_API,
        url: `${MESSAGE}/app/bulletinLog/getUnreadCount`,
        method: 'POST',
        headers: {
            'platform': 'pc'
        },
        data:{
            msgType: 1
        }
    })
}

// 获取通知公告
export function getAppBulletinLogList(pageSize, pageNum, msgType) {
    return request({
        baseURL: import.meta.env.VITE_APP_BASE_API,
        url: `${MESSAGE}/app/bulletinLog/list?pageSize=${pageSize}&pageNum=${pageNum}&msgType=${msgType}`,
        method: 'GET',
        headers: {
            'platform': 'pc'
        },
    })
}
// 通知公告已知悉
export function getAppBulletinLogRead(id) {
    return request({
        baseURL: import.meta.env.VITE_APP_BASE_API,
        url: `${MESSAGE}/app/bulletinLog/read/${id}`,
        method: 'GET',
        headers: {
            'platform': 'pc'
        },
    })
}

// 待办系统分类列表
export function getSource() {
    return request({
        baseURL: import.meta.env.VITE_APP_BASE_API,
        url: `${MESSAGE}/notifyTask/getSource`,
        method: 'get',
        headers: {
            'Content-Type': 'application/json'
        },
    })
}

// 待办分类列表
export function getAppNotifyTaskList(pageSize, pageNum, acceptor) {
    return request({
        baseURL: import.meta.env.VITE_APP_BASE_API,
        url: `${MESSAGE}/notifyTask/list?pageSize=${pageSize}&pageNum=${pageNum}&acceptor=${acceptor}`,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    })
}