<template>
  <div :class="{ 'has-logo': showLogo }" :style="{ backgroundColor: bgColor }">
    <Logo v-if="showLogo" :collapse="isCollapse" />
    <el-scrollbar :class="sideTheme" wrap-class="scrollbar-wrapper">
      <transition :enter-active-class="proxy?.animate.menuSearchAnimate.enter" mode="out-in">
        <el-menu :default-active="activeMenu as string" :collapse="isCollapse" :background-color="bgColor" :text-color="textColor" :unique-opened="true" :active-text-color="theme" :collapse-transition="false" mode="vertical">
          <SidebarItem v-for="(route, index) in sidebarRouters" :key="route.path + index" :item="route" :base-path="route.path" />
        </el-menu>
      </transition>
    </el-scrollbar>
  </div>
</template>

<script setup lang="ts">
import Logo from './Logo.vue'
import SidebarItem from './SidebarItem.vue'
import variables from '@/assets/styles/variables.module.scss'
import useAppStore from '@/store/modules/app'
import useSettingsStore from '@/store/modules/settings'
import usePermissionStore from '@/store/modules/permission'

import { getOverdueInfo } from '@/api/tool/gen/a'
const object_number = ref({})
const ref1 = ref(null)
onMounted(() => {
  getOverdueInfo().then((response) => {
    /**
    FREE(0, "待领取"),
    NO_DEMAND(5, "无需求"),
    NOT_CONTACTED(10, "未联系"),
    UN_CONTACTED(11, "待联系"),
    UN_CONNECTED(20, "未接通"),
    RESERVE(30, "储备"),
    INTENTIONAL(40, "有意向"),
    INVITED(50, "已邀约"),
    VISITED(60, "已拜访"),
    SIGNED(70, "已签约"),
    INPUT_ORDER(100, "输单"),
     **/
    object_number.value = response.data
    localStorage.setItem('user', JSON.stringify(object_number.value))
  })
})

const { proxy } = getCurrentInstance() as ComponentInternalInstance

const route = useRoute()
const appStore = useAppStore()
const settingsStore = useSettingsStore()
const permissionStore = usePermissionStore()

const sidebarRouters = computed(() => permissionStore.sidebarRouters)
const showLogo = computed(() => settingsStore.sidebarLogo)
const sideTheme = computed(() => settingsStore.sideTheme)
const theme = computed(() => settingsStore.theme)
const isCollapse = computed(() => !appStore.sidebar.opened)

const activeMenu = computed(() => {
  const { meta, path } = route
  // if set path, the sidebar will highlight the path you set
  if (meta.activeMenu) {
    return meta.activeMenu
  }
  return path
})

const bgColor = computed(() => sideTheme.value === 'theme-dark' ? variables.menuBackground : variables.menuLightBackground)
const textColor = computed(() => sideTheme.value === 'theme-dark' ? variables.menuColor : variables.menuLightColor)
</script>
